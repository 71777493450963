


  .timeline_container{
    display: flex;
    flex-direction: column;
  }

.responsive_timeline{
  margin-left:150px;
}

.timeline_responsive_tree{
    height: 600px;
    width: 555px;
    /* margin-left: 163px; */
}

.image_responsive{
    /* margin-left: 230px; */
    height: 290px;
}

.mask1{
  margin-top: 18px;
}

@media screen and (max-width: 576px) {
  .responsive_timeline{
    margin-left:9px;
  }
  .timeline_container{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .timeline_responsive_tree{
    height: 270px;
    width: -webkit-fill-available;
    margin-left: 0px;
  }

  .image_responsive{
    margin-left: 0px;
  }
  .mask1{
    margin-top: 10px;
  }
}
  