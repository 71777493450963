@keyframes phoneRing {
    0% {
      transform: rotateZ(-15deg) translateY(0);
    }
    10% {
      transform: rotateZ(15deg) translateY(-5px);
    }
    20% {
      transform: rotateZ(-15deg) translateY(0);
    }
    30% {
      transform: rotateZ(15deg) translateY(-5px);
    }
    40% {
      transform: rotateZ(-15deg) translateY(0);
    }
    50% {
      transform: rotateZ(15deg) translateY(-5px);
    }
    60% {
      transform: rotateZ(-15deg) translateY(0);
    }
    70% {
      transform: rotateZ(15deg) translateY(-5px);
    }
    80% {
      transform: rotateZ(-15deg) translateY(0);
    }
    90% {
      transform: rotateZ(15deg) translateY(-5px);
    }
    100% {
      transform: rotateZ(0deg) translateY(0);
    }
  }
  
  .phone-icon-container {
    position: relative;
    display: inline-block;
  }
  
  
  
@media screen and (max-width: 768px) {
   .responsive_phone{
     display: none;
   }
}
  