/* CSS styles for site title */
.site-title {
    color: #f17732;
}

/* CSS styles for blog post titles and links */
.blog_post_title,
.blog_post_title a {
    font-display: swap;
}

/* CSS styles for blog post metadata */
li.mr_blog_post_meta,
li.mr_blog_post_meta a {
    font-display: swap;
}

/* CSS styles for blog post text and paragraphs */
.mr_blog_post_text,
.mr_blog_post_text a,
.mr_blog_post_text p {
    font-display: swap;
}

/* CSS styles for post readmore button */
.post_readmore a.mr_post_button {
    font-display: swap;
}

/* CSS styles for post titles */
h3.mr_post_title {
    font-display: swap;
}

/* CSS styles for post metadata */
li.mr_post_meta,
li.mr_post_meta a {
    font-display: swap;
}

/* CSS styles for post text, paragraphs, and tags */
.mr_post_text,
.mr_post_text .text,
.mr_post_text p,
.mr_post_text p,
.mr_post_tags a,
.mr_post_tag_icon {
    font-display: swap;
}

/* CSS styles for search box and results */
.search_tx_box .search_title,
.search_tx_box .search_text,
.search_area_df .error_button,
.error-content .big-title.mr_404,
.error-content .title.mr_404_title,
.error-content p.mr_page_text,
.error_button {
    font-display: swap;
}

/* CSS styles for main menu navigation */
.main-menu .navigation.mr_menu > li > a,
.main-menu .navigation.mr_menu > li > ul > li > a,
.main-menu .navigation.mr_menu > li > ul > li > ul > li > a {
    font-display: swap;
}

/* CSS styles for various headings and widgets */
.wp-block-search .wp-block-search__label,
.wp-block-group__inner-container h3,
.mrwidget h3,
.mrwidget h1,
.mrwidget h2,
.mrwidget h6,
.footer h3,
footer h3,
.mr_footer h3 {
    font-display: swap;
}

/* CSS styles for banner title */
.mr_banner_title {
    text-align: inherit;
    color: #fcfcfc;
    font-display: swap;
}

/* CSS styles for breadcrumb navigation */
.mr_bread_list li,
.mr_bread_list,
.mr_bread_list li a,
.mr_bread_list .breadcrumb-item::before,
.mr_bread_list .breadcrumb-item:before {
    text-align: inherit;
    color: #ffffff;
    font-display: swap;
}

/* CSS styles for comments section */
.comments-area .title h3,
.comments-area .outer-box .single-comment .text-holder .top h3,
.comments-area .outer-box .single-comment .text-holder .top span,
.comments-area .outer-box .single-comment .text-holder .text p,
.comments-area .outer-box .single-comment .text-holder .text .reply a,
.comments-form-area h3,
.comments-form-area .logged-in-as a,
.default-form input[type='text'].form-control,
.default-form input[type='email'].form-control,
.default-form input[type='tel'].form-control,
.default-form input[type='password'].form-control,
.default-form textarea.form-control,
.comments-form-area .theme-btn-six .txt {
    font-display: swap;
}

/* CSS styles for page titles and backgrounds */
.mr_post_post.mr_page_title,
.mr_page_page_title.page-title,
.mr_index_index_title.mr_page_title {
    background-repeat: no-repeat;
    background-position: center top;
    background-image: url('/public/images/breadcrumb-bg-1.jpg');
    background-size: cover;
}
