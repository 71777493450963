.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #FFB237;
    color: rgb(133, 17, 17);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0;
   z-index: 99;
    transition: opacity 0.4s, visibility 0.4s;
  }
  
  .scroll-to-top-button.show {
    opacity: 1;
    visibility: visible;
  }
  
  .scroll-to-top-button i {
    font-size: 20px;
  }
  