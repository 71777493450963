
.elementor-widget-image-carousel .swiper,
.elementor-widget-image-carousel .swiper-container {
    position: static
}

.elementor-widget-image-carousel .swiper-container .swiper-slide figure,
.elementor-widget-image-carousel .swiper .swiper-slide figure {
    line-height: inherit
}

.elementor-widget-image-carousel .swiper-slide {
    text-align: center
}

.elementor-image-carousel-wrapper:not(.swiper-container-initialized):not(.swiper-initialized) .swiper-slide {
    max-width: calc(100% / var(--e-image-carousel-slides-to-show, 3))
}


.defult_popup .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    z-index: 9;
}

.defult_popup .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    width: 80%;
}

/* Close button */

.defult_popup .close-popup {
    position: relative;
    top: 100px;
    right: 100px;
    font-size: 70px;
    background: none;
    border: none;
    cursor: pointer;
    background: #7a7a7a;
    color: #fff;
    /* padding: 20px; */
    /* height: 100px; */
    border: 1px solid #616161;
    padding: 10px;
    border-radius: 50%;
    line-height: 43px;
    float: right;
    z-index: 999;
}

.defult_popup .open-popup {
    position: relative;
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    height: 60px;
    width: 200px;
    color: #fff;
    text-align: center;
    /* padding: 18px 40px; */
    text-transform: capitalize;
    z-index: 1;
    box-shadow: 0px 30px 30px rgb(0 0 0 / 10%);
    border-radius: 30px;
    transition: all 500ms ease;
    background-color: #396cf0;
}

.defult_popup .open-popup:hover {
    color: #fff;
    background-color: #222;
}




.elementor-heading-title {
    padding: 0;
    margin: 0;
    line-height: 1
}

.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a {
    color: inherit;
    font-size: inherit;
    line-height: inherit
}

.elementor-widget-heading .elementor-heading-title.elementor-size-small {
    font-size: 15px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-medium {
    font-size: 19px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-large {
    font-size: 29px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-xl {
    font-size: 39px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-xxl {
    font-size: 59px;
}


.elementor-widget-divider {
    --divider-border-style: none;
    --divider-border-width: 1px;
    --divider-color: #0c0d0e;
    --divider-icon-size: 20px;
    --divider-element-spacing: 10px;
    --divider-pattern-height: 24px;
    --divider-pattern-size: 20px;
    --divider-pattern-url: none;
    --divider-pattern-repeat: repeat-x
}

.elementor-widget-divider .elementor-divider {
    display: flex
}

.elementor-widget-divider .elementor-divider__text {
    font-size: 15px;
    line-height: 1;
    max-width: 95%
}

.elementor-widget-divider .elementor-divider__element {
    margin: 0 var(--divider-element-spacing);
    flex-shrink: 0
}

.elementor-widget-divider .elementor-icon {
    font-size: var(--divider-icon-size)
}

.elementor-widget-divider .elementor-divider-separator {
    display: flex;
    margin: 0;
    direction: ltr
}

.elementor-widget-divider--view-line_icon .elementor-divider-separator,
.elementor-widget-divider--view-line_text .elementor-divider-separator {
    align-items: center
}

.elementor-widget-divider--view-line_icon .elementor-divider-separator:after,
.elementor-widget-divider--view-line_icon .elementor-divider-separator:before,
.elementor-widget-divider--view-line_text .elementor-divider-separator:after,
.elementor-widget-divider--view-line_text .elementor-divider-separator:before {
    display: block;
    content: "";
    border-bottom: 0;
    flex-grow: 1;
    border-top: var(--divider-border-width) var(--divider-border-style) var(--divider-color)
}

.elementor-widget-divider--element-align-left .elementor-divider .elementor-divider-separator>.elementor-divider__svg:first-of-type {
    flex-grow: 0;
    flex-shrink: 100
}

.elementor-widget-divider--element-align-left .elementor-divider-separator:before {
    content: none
}

.elementor-widget-divider--element-align-left .elementor-divider__element {
    margin-left: 0
}

.elementor-widget-divider--element-align-right .elementor-divider .elementor-divider-separator>.elementor-divider__svg:last-of-type {
    flex-grow: 0;
    flex-shrink: 100
}

.elementor-widget-divider--element-align-right .elementor-divider-separator:after {
    content: none
}

.elementor-widget-divider--element-align-right .elementor-divider__element {
    margin-right: 0
}

.elementor-widget-divider:not(.elementor-widget-divider--view-line_text):not(.elementor-widget-divider--view-line_icon) .elementor-divider-separator {
    border-top: var(--divider-border-width) var(--divider-border-style) var(--divider-color)
}

.elementor-widget-divider--separator-type-pattern {
    --divider-border-style: none
}

.elementor-widget-divider--separator-type-pattern.elementor-widget-divider--view-line .elementor-divider-separator,
.elementor-widget-divider--separator-type-pattern:not(.elementor-widget-divider--view-line) .elementor-divider-separator:after,
.elementor-widget-divider--separator-type-pattern:not(.elementor-widget-divider--view-line) .elementor-divider-separator:before,
.elementor-widget-divider--separator-type-pattern:not([class*=elementor-widget-divider--view]) .elementor-divider-separator {
    width: 100%;
    min-height: var(--divider-pattern-height);
    -webkit-mask-size: var(--divider-pattern-size) 100%;
    mask-size: var(--divider-pattern-size) 100%;
    -webkit-mask-repeat: var(--divider-pattern-repeat);
    mask-repeat: var(--divider-pattern-repeat);
    background-color: var(--divider-color);
    -webkit-mask-image: var(--divider-pattern-url);
    mask-image: var(--divider-pattern-url)
}

.elementor-widget-divider--no-spacing {
    --divider-pattern-size: auto
}

.elementor-widget-divider--bg-round {
    --divider-pattern-repeat: round
}

.rtl .elementor-widget-divider .elementor-divider__text {
    direction: rtl
}

.e-con-inner>.elementor-widget-divider,
.e-con>.elementor-widget-divider {
    width: var(--container-widget-width, 100%);
    --flex-grow: var(--container-widget-flex-grow)
}