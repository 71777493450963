/* Styles for modal overlay */


/* Or replace the background image with a gradient */
.pagintion_button {
    font-size: 18px;
    display: inline-block;
    outline: 0;
    border: 0;
    cursor: pointer;
    will-change: box-shadow,transform;
    background: #fdc900;
    box-shadow: 0px 0.01em 0.01em rgb(45 35 66 / 40%), 0px 0.3em 0.7em -0.01em rgb(45 35 66 / 30%), inset 0px -0.01em 0px rgb(58 65 111 / 50%);
    padding: 0 2em;
    border-radius: 0.3em;
    color: #fff;
    height: 2.6em;
    text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
    transition: box-shadow 0.15s ease, transform 0.15s ease;
    margin-left: 4px;
  }

  
  .pagintion_button:hover {
    box-shadow: 0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -0.1em 0px #3c4fe0;
    transform: translateY(-0.1em);
  }
  
  .pagintion_button:active {
    box-shadow: #fdc900;
    transform: translateY(0em);
  }
  .pagination_button {
    font-size: 18px;
    display: inline-block;
    outline: 0;
    border: 0;
    cursor: pointer;
    will-change: box-shadow, transform;
    background: #fdc900;
    box-shadow: 0px 0.01em 0.01em rgb(45 35 66 / 40%), 0px 0.3em 0.7em -0.01em rgb(45 35 66 / 30%), inset 0px -0.01em 0px rgb(58 65 111 / 50%);
    padding: 0 2em;
    border-radius: 0.3em;
    color: #fff;
    height: 2.6em;
    text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
    transition: box-shadow 0.15s ease, transform 0.15s ease;
    margin-left: 4px;
}

.button-container {
    display: flex;
    flex-direction: row; /* Ensure buttons are displayed horizontally */
    justify-content: center; /* Center align buttons */
    gap: 10px; /* Space between buttons */
}

@media (max-width: 768px) {
    .pagination_button {
        font-size: 16px; /* Adjust font size for mobile */
        padding: 0 1.5em; /* Adjust padding for mobile */
        height: 2.4em; /* Adjust height for mobile */
        margin-left: 0; /* Remove left margin for better alignment */
        width: auto; /* Adjust width for mobile */
        box-sizing: border-box; /* Ensure padding and border are included in width */
        margin-bottom: 8px; /* Add margin at the bottom for spacing between buttons */
        margin-top: 0; /* Remove top margin for consistency */
    }

    .button-container {
        flex-direction: row; /* Ensure buttons are displayed horizontally in mobile view */
        justify-content: space-around; /* Space buttons evenly */
        width: 100%; /* Full width container */
        margin-bottom: 10px; /* Add some margin at the bottom */
    }
}



.message-box{
    margin-left: 20px;
}



main.table {
    
    background-color: #b1a7b136;

    backdrop-filter: blur(7px);
    box-shadow: 0 .4rem .8rem #0005;
    border-radius: .8rem;
    margin: 10px;
    overflow: hidden;
}



.table__header {
    width: 100%;
    height: 10%;
    background-color: #fff4;
    padding: .8rem 1rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table__header .input-group {
    width: 155px;
    height: 100%;
    background-color: #fff5;
    padding: 0 .8rem;
    border-radius: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: .2s;
}

.table__header .input-group:hover {
    width: 45%;
    background-color: #fff8;
    box-shadow: 0 .1rem .4rem #0002;
}

.table__header .input-group img {
    width: 1.2rem;
    height: 1.2rem;
}

.table__header .input-group input {
    width: 100%;
    padding: 0 .5rem 0 .3rem;
    background-color: transparent;
    border: none;
    outline: none;
}

.table__body {
    width: 95%;
    max-height: calc(89% - 1.6rem);
    background-color: #fffb;

    margin: .8rem auto;
    border-radius: .6rem;

    overflow: auto;
    overflow: overlay;
}


.table__body::-webkit-scrollbar{
    width: 0.5rem;
    height: 0.5rem;
}

.table__body::-webkit-scrollbar-thumb{
    border-radius: .5rem;
    background-color: #0004;
    visibility: hidden;
}

.table__body:hover::-webkit-scrollbar-thumb{ 
    visibility: visible;
}


table {
    width: 100%;
}

td img {
    width: 36px;
    height: 36px;
    margin-right: .5rem;
    border-radius: 50%;

    vertical-align: middle;
}

table, th, td {
    border-collapse: collapse;
    padding: 1rem;
    text-align: left;
}

thead th {
    position: sticky;
    top: 0;
    left: 0;
    background-color: #d5d1defe;
    cursor: pointer;
    text-transform: capitalize;
}

tbody tr:nth-child(even) {
    background-color: #0000000b;
}

tbody tr {
    --delay: .1s;
    transition: .5s ease-in-out var(--delay), background-color 0s;
}

tbody tr.hide {
    opacity: 0;
    transform: translateX(100%);
}

tbody tr:hover {
    background-color: #fff6 !important;
}

tbody tr td,
tbody tr td p,
tbody tr td img {
    transition: .2s ease-in-out;
}

tbody tr.hide td,
tbody tr.hide td p {
    padding: 0;
    font: 0 / 0 sans-serif;
    transition: .2s ease-in-out .5s;
}

tbody tr.hide td img {
    width: 0;
    height: 0;
    transition: .2s ease-in-out .5s;
}

.status {
    padding: .4rem 0;
    border-radius: 2rem;
    text-align: center;
}

.status.delivered {
    background-color: #86e49d;
    color: #006b21;
}

.status.cancelled {
    background-color: #d893a3;
    color: #b30021;
}

.status.pending {
    background-color: #ebc474;
}

.status.shipped {
    background-color: #6fcaea;
}


@media (max-width: 1000px) {
    td:not(:first-of-type) {
        min-width: 12.1rem;
    }
}

thead th span.icon-arrow {
    display: inline-block;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    border: 1.4px solid transparent;
    
    text-align: center;
    font-size: 1rem;
    
    margin-left: .5rem;
    transition: .2s ease-in-out;
}

thead th:hover span.icon-arrow{
    border: 1.4px solid #6c00bd;
}

thead th:hover {
    color: #6c00bd;
}

thead th.active span.icon-arrow{
    background-color: #6c00bd;
    color: #fff;
}

thead th.asc span.icon-arrow{
    transform: rotate(180deg);
}

thead th.active,tbody td.active {
    color: #6c00bd;
}

.export__file {
    position: relative;
}

.export_file .export_file-btn {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background: #fff6  center / 80% no-repeat;
    border-radius: 50%;
    transition: .2s ease-in-out;
}

.export_file .export_file-btn:hover { 
    background-color: #fff;
    transform: scale(1.15);
    cursor: pointer;
}

.export__file input {
    display: none;
}

.export_file .export_file-options {
    position: absolute;
    right: 0;
    
    width: 12rem;
    border-radius: .5rem;
    overflow: hidden;
    text-align: center;

    opacity: 0;
    transform: scale(.8);
    transform-origin: top right;
    
    box-shadow: 0 .2rem .5rem #0004;
    
    transition: .2s;
}

.export_file input:checked + .export_file-options {
    opacity: 1;
    transform: scale(1);
    z-index: 100;
}

.export_file .export_file-options label{
    display: block;
    width: 100%;
    padding: .6rem 0;
    background-color: #f2f2f2;
    
    display: flex;
    justify-content: space-around;
    align-items: center;

    transition: .2s ease-in-out;
}

.export_file .export_file-options label:first-of-type{
    padding: 1rem 0;
    background-color: #86e49d !important;
}

.export_file .export_file-options label:hover{
    transform: scale(1.05);
    background-color: #fff;
    cursor: pointer;
}

.export_file .export_file-options img{
    width: 2rem;
    height: auto;
}

.responsive_textarea{
    width: 400px;
    height: 70px; 
    padding:7px;
    background-color:white;
    border-color:#fdc900;
    border-style:solid;
    
}



.compose-btn {
    background-color: #ccc; /* Default color for disabled state */
    color: #333;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: not-allowed; /* Change cursor to indicate disabled state */
  }
  
  .compose-btn.enabled {
    background-color: #fcd900; /* Color for enabled state */
    color: #fff;
    cursor: pointer; /* Change cursor to indicate enabled state */
  }


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
    backdrop-filter: blur(5px); /* Apply a blur effect to the background */
  
    z-index: 1050;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 200px;

  }
  
  /* Styles for modal content */
  .modal-content {
    background-color: #fff; /* White background for the modal content */
    border-radius: 8px; /* Add rounded corners to the modal content */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle box shadow */
    max-width: 600px; /* Set a maximum width for the modal content */
    width: 100%; /* Make the modal content responsive */
    margin: 0 auto; /* Center the modal content horizontally */
    height: 700px;
  }
  
  /* Styles for mobile view */
  @media (max-width: 768px) {
    .modal-content {
     /* Adjust max-width to 90% of viewport width for mobile view */
      /* max-height: 100vh; Adjust max-height to 90% of viewport height for mobile view */
      height: 700px;
      width: 310px;
       /* Reduce padding for better use of space on smaller screens */
    }
    .responsive_textarea {
        width: 100%;
        height: 50px; /* Adjust height as needed */
    }
    .compose-form {
        flex-direction: column; /* Ensure form items are stacked */
        align-items: center; /* Center align form items */
    }
    .pagination_button {
        font-size: 16px; /* Adjust font size for mobile */
        padding: 0 1.5em; /* Adjust padding for mobile */
        height: 2.4em; /* Adjust height for mobile */
        margin-left: 2px; /* Adjust margin for better spacing */
        width: 30%; /* Make buttons full-width on mobile */
        box-sizing: border-box; /* Ensure padding and border are included in width */
        margin-bottom: 8px; /* Add margin at the bottom for spacing between buttons */
    }
    .selected-emails {
        max-height: 150px; 
        color: black;
      
        /* Increase max height for better scrolling area */
    }
  }

  /* Styles for the compose form */
  .compose-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  .form-control {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .btn {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-primary {
    background-color: #fdc900;
    color: #fff;
    border: none;
  }
  
  .btn-secondary {
    background-color: #ccc;
    color: #333;
    border: none;
  }
  
  /* Styles for selected emails */
  .selected-emails {
    max-height: 100px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 5px;
    margin-bottom: 10px;
  }
  
  .selected-email-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid #ccc;
  }
  
  .cancel-btn {
    background-color: transparent;
    border: none;
    color: #999;
    cursor: pointer;
    font-size: 16px;
  }
  
  .cancel-btn:hover {
    color: #666;
  }