.card_team {
    height: 390px;
    border-radius: 20px;
    padding: 5px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    background-image: linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB);
    margin-left: 10px;
  }
  
  .card__content_team {
    background: rgb(252, 252, 252);
    border-radius: 11px;
    width: 100%;
    height: 100%;
  }