
.elementor-widget-heading .elementor-heading-title.elementor-size-small {
    font-size: 15px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-medium {
    font-size: 19px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-large {
    font-size: 29px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-xl {
    font-size: 39px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-xxl {
    font-size: 59px
}
.elementor-widget-divider {
    --divider-border-style: none;
    --divider-border-width: 1px;
    --divider-color: #0c0d0e;
    --divider-icon-size: 20px;
    --divider-element-spacing: 10px;
    --divider-pattern-height: 24px;
    --divider-pattern-size: 20px;
    --divider-pattern-url: none;
    --divider-pattern-repeat: repeat-x
}

.elementor-widget-divider .elementor-divider {
    display: flex
}

.elementor-widget-divider .elementor-divider__text {
    font-size: 15px;
    line-height: 1;
    max-width: 95%
}

.elementor-widget-divider .elementor-divider__element {
    margin: 0 var(--divider-element-spacing);
    flex-shrink: 0
}

.elementor-widget-divider .elementor-icon {
    font-size: var(--divider-icon-size)
}

.elementor-widget-divider .elementor-divider-separator {
    display: flex;
    margin: 0;
    direction: ltr
}

.elementor-widget-divider--view-line_icon .elementor-divider-separator,
.elementor-widget-divider--view-line_text .elementor-divider-separator {
    align-items: center
}

.elementor-widget-divider--view-line_icon .elementor-divider-separator:after,
.elementor-widget-divider--view-line_icon .elementor-divider-separator:before,
.elementor-widget-divider--view-line_text .elementor-divider-separator:after,
.elementor-widget-divider--view-line_text .elementor-divider-separator:before {
    display: block;
    content: "";
    border-bottom: 0;
    flex-grow: 1;
    border-top: var(--divider-border-width) var(--divider-border-style) var(--divider-color)
}

.elementor-widget-divider--element-align-left .elementor-divider .elementor-divider-separator>.elementor-divider__svg:first-of-type {
    flex-grow: 0;
    flex-shrink: 100
}

.elementor-widget-divider--element-align-left .elementor-divider-separator:before {
    content: none
}

.elementor-widget-divider--element-align-left .elementor-divider__element {
    margin-left: 0
}

.elementor-widget-divider--element-align-right .elementor-divider .elementor-divider-separator>.elementor-divider__svg:last-of-type {
    flex-grow: 0;
    flex-shrink: 100
}

.elementor-widget-divider--element-align-right .elementor-divider-separator:after {
    content: none
}

.elementor-widget-divider--element-align-right .elementor-divider__element {
    margin-right: 0
}

.elementor-widget-divider:not(.elementor-widget-divider--view-line_text):not(.elementor-widget-divider--view-line_icon) .elementor-divider-separator {
    border-top: var(--divider-border-width) var(--divider-border-style) var(--divider-color)
}

.elementor-widget-divider--separator-type-pattern {
    --divider-border-style: none
}

.elementor-widget-divider--separator-type-pattern.elementor-widget-divider--view-line .elementor-divider-separator,
.elementor-widget-divider--separator-type-pattern:not(.elementor-widget-divider--view-line) .elementor-divider-separator:after,
.elementor-widget-divider--separator-type-pattern:not(.elementor-widget-divider--view-line) .elementor-divider-separator:before,
.elementor-widget-divider--separator-type-pattern:not([class*=elementor-widget-divider--view]) .elementor-divider-separator {
    width: 100%;
    min-height: var(--divider-pattern-height);
    -webkit-mask-size: var(--divider-pattern-size) 100%;
    mask-size: var(--divider-pattern-size) 100%;
    -webkit-mask-repeat: var(--divider-pattern-repeat);
    mask-repeat: var(--divider-pattern-repeat);
    background-color: var(--divider-color);
    -webkit-mask-image: var(--divider-pattern-url);
    mask-image: var(--divider-pattern-url)
}

.elementor-widget-divider--no-spacing {
    --divider-pattern-size: auto
}

.elementor-widget-divider--bg-round {
    --divider-pattern-repeat: round
}

.rtl .elementor-widget-divider .elementor-divider__text {
    direction: rtl
}

.e-con-inner>.elementor-widget-divider,
.e-con>.elementor-widget-divider {
    width: var(--container-widget-width, 100%);
    --flex-grow: var(--container-widget-flex-grow)
}


.elementor-widget-image-box .elementor-image-box-content {
    width: 100%
}

@media (min-width:768px) {

    .elementor-widget-image-box.elementor-position-left .elementor-image-box-wrapper,
    .elementor-widget-image-box.elementor-position-right .elementor-image-box-wrapper {
        display: flex
    }

    .elementor-widget-image-box.elementor-position-right .elementor-image-box-wrapper {
        text-align: right;
        flex-direction: row-reverse
    }

    .elementor-widget-image-box.elementor-position-left .elementor-image-box-wrapper {
        text-align: left;
        flex-direction: row
    }

    .elementor-widget-image-box.elementor-position-top .elementor-image-box-img {
        margin: auto
    }

    .elementor-widget-image-box.elementor-vertical-align-top .elementor-image-box-wrapper {
        align-items: flex-start
    }

    .elementor-widget-image-box.elementor-vertical-align-middle .elementor-image-box-wrapper {
        align-items: center
    }

    .elementor-widget-image-box.elementor-vertical-align-bottom .elementor-image-box-wrapper {
        align-items: flex-end
    }
}

@media (max-width:767px) {
    .elementor-widget-image-box .elementor-image-box-img {
        margin-left: auto !important;
        margin-right: auto !important;
        margin-bottom: 15px
    }
}

.elementor-widget-image-box .elementor-image-box-img {
    display: inline-block
}

.elementor-widget-image-box .elementor-image-box-title a {
    color: inherit
}

.elementor-widget-image-box .elementor-image-box-wrapper {
    text-align: center
}

.elementor-widget-image-box .elementor-image-box-description {
    margin: 0
}


.animate__animated {
    opacity: 0;
  }
  
  .animate__fadeInUp {
    animation: fadeInUp 1s ease forwards;
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }