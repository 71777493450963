
@media screen and (min-width: 768px){
  .container_gallery{
    padding: 75px 0;
    margin: 0 auto;
    width: 1140px;
  }
}

/* Default style for desktop view */
.gallery_heading {
position: relative;
margin-bottom: 45px;
font-family: 'Poppins', sans-serif;
font-size: 29px; /* Desktop font size */
color: #424242;
}

/* Mobile view styles */
@media (max-width: 768px) {
.gallery_heading {
  font-size: 22px; /* Adjusted font size for mobile */
  margin-bottom: 30px; /* Reduced margin for mobile */
}
}


.gallery-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70vh;
}

.item_gallery {
  flex: 1;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: none;
  transition: flex 0.8s ease;
  
  &:hover{
    flex: 7;
  }
}

.item-1 { 
  background-image: url('../../public/images/g2.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}

.item-2 { 
  background-image: url('../../public/images/g3.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}

.item-3 { 
  background-image: url('../../public/images/g4.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}

.item-4 { 
  background-image: url('../../public/images/g5.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}

.item-5 { 
  background-image: url('../../public/images/g6.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}


.item-6 { 
  background-image: url('../../public/images/g7.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}

.item-7 { 
  background-image: url('../../public/images/g8.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}

.item-8 { 
  background-image: url('../../public/images/g9.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}

.item-9 { 
  background-image: url('../../public/images/z8.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}
 .item-11{
  background-image: url('../../public/images/z9.jpeg');
  border-radius: 20px;
  margin-right: 2px;
} 
.item-13{
  background-image: url('../../public/images/z2.jpeg');
  border-radius: 20px;
  margin-right: 2px;
} 
 .item-14{
  background-image: url('../../public/images/z12.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}

/* .item-12{
  background-image: url('../../public/images/g8.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}  */

/*   
.item-16{
  background-image: url('../../public/images/g9.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}  */

 .item-16{
  background-image: url('../../public/images/e1.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}
.item-17{
  background-image: url('../../public/images/e2.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}
.item-18{
  background-image: url('../../public/images/e3.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}
.item-19{
  background-image: url('../../public/images/e4.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}
.item-21{
  background-image: url('../../public/images/e5.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}
.item-23{
  background-image: url('../../public/images/e6.jpeg');
  border-radius: 20px;
  margin-right: 2px;
} 
 
.item-24{
  background-image: url('../../public/images/k7.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}

.item-25{
  background-image: url('../../public/images/k2.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}
.item-26{
  background-image: url('../../public/images/k8.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}

.item-27{
  background-image: url('../../public/images/k4.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}

.item-28{
  background-image: url('../../public/images/k5.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}

.item-30{
  background-image: url('../../public/images/p1.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}

.item-31{
  background-image: url('../../public/images/p2.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}

.item-32{
  background-image: url('../../public/images/p3.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}
.item-33{
  background-image: url('../../public/images/p5.jpeg');
  border-radius: 20px;
  margin-right: 2px;
}







.social{
  position: absolute;
  right: 35px;
  bottom: 0;
  
  img{
    display: block;
    width: 32px;
  }
}


@media (max-width: 576px) {
  .gallery-wrap {
    flex-direction: column;
    height: auto;
  }

  .item_gallery {
    flex: none;
    width: 400px;
    height: 200px; /* Adjust the height as needed */
    margin-bottom: 10px; /* Add some space between items */
  }

  .item-1{
    display: none;
  }

  .item-9{
    display: none;
  }

}


.mr_post_post.mr_page_title_1,
.mr_page_page_title_1.page-title_1,
.mr_index_index_title_1.mr_page_title_1 {
  background-repeat: no-repeat;
  background-position: center top;
  background-image: url('/public/images/ab.jpeg');
  background-size: cover;
}