.elementor-heading-title {
    padding: 0;
    margin: 0;
    line-height: 1
}

.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a {
    color: inherit;
    font-size: inherit;
    line-height: inherit
}

.elementor-widget-heading .elementor-heading-title.elementor-size-small {
    font-size: 15px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-medium {
    font-size: 19px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-large {
    font-size: 29px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-xl {
    font-size: 39px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-xxl {
    font-size: 59px
}


.card_dd{
   
    border-radius: 10px 10px 10px 10px;
    padding: 10px;
    background: white;
    background-blend-mode: normal, normal, screen, overlay, normal;
   
    object-fit: cover;
    margin-right: 0px;
}

